import React from 'react'

export default () => (
  <section className="pregnancy-calendar">
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-offset-1 col-md-6">
          <p className="pregnancy-calendar__heading">
            Kalendarz ciążowy              </p>
          <div className="pregnancy-calendar__content">
            <p>Kalendarz ciążowy to zbiór informacji mówiących o tym jak powinna wyglądać opieka nad przyszłą mamą i dzieckiem w trakcie ciąży. Dzięki niemu dowiesz się kiedy wykonywane są podstawowe badania ultrasonograficzne i badania laboratoryjne.</p>
          </div>
          <div className="pregnancy-calendar__button hidden-xs">
            <a className="btn btn-primary" href="#">
              przejdź
            </a>
          </div>
        </div>
        <div className="col-sm-6 col-md-5 pregnancy-calendar__interaction__wrapper">
          <div className="pregnancy-calendar__interaction">
            <div className="pregnancy-calendar__interaction__icon__container">
              <div className="heart">
                <svg width={270} height={224} viewBox="0 0 270 224" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title>Group 18 Copy</title><defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a"><stop stopColor="#D1298C" offset="0%" /><stop stopColor="#E5086E" offset="100%" /></linearGradient><path d="M105.643 211.75c7.884 7.884 18.25 11.696 28.589 11.566 10.338.145 20.704-3.682 28.602-11.565l84.884-84.455c29.123-29.124 29.123-76.34 0-105.464-29.108-29.11-76.322-29.11-105.43 0l-7.551 7.436-7.465-7.436c-29.108-29.11-76.322-29.11-105.43 0-29.123 29.124-29.123 76.326 0 105.45l83.801 84.469z" id="b" /></defs><use fill="url(#a)" xlinkHref="#b" fillRule="evenodd" /></svg>                      </div>
              <div className="pregnancy-calendar__interaction__icon" data-value={1} data-url="/trymestr-ciazy-czego-sie-spodziewac/">
                <svg width={270} height={224} viewBox="0 0 270 224" xmlns="http://www.w3.org/2000/svg"><title>Group 18 Copy</title><g mask="url(#cxz)" fill="#FFF" fillRule="evenodd"><path d="M125.988 168.513c-4.38-3.63-8.685-7.543-8.504-10.233.201-2.975 3.315-6.98 5.792-12.474 4.447 9.764 6.192 17.162 2.712 22.707m35.968 22.672c-.487-8.83-1.269-15.869-8.542-28.312 3.352-2.286 6.003-4.373 7.074-5.697 4.314-5.34-13.352-22.318-21.665-29.831a22.31 22.31 0 0 0 1.747.59c4.76 1.346 8.616-5.314 10.471-9.96 5.284-13.23 3.316-27.42-7.389-31.695-10.704-4.275-23.664 2.984-28.947 16.214-2.839 7.106-6.086 25.845-6.086 25.845s4.96 4.964 9.851 6.576c-3.816 5.36-12.36 20-11.761 24.08 1.097 7.496 8.004 13.614 11.414 15.978.346.24.687.518 1.024.812-9.633 12.093-6.928 28.69-6.663 31.277 1.212 11.851 12.525 18.89 12.525 18.89h27.021s.203-5.469 4.542-12.46c3.425-5.517 5.92-12.615 5.384-22.307" /></g></svg>                      </div>
              <div className="pregnancy-calendar__interaction__icon" data-value={2} data-url="/ii-trymestr-ciazy-najwazniejsze-badania/">
                <svg width={270} height={224} viewBox="0 0 270 224" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><title>Group 15</title><defs><linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a"><stop stopColor="#D1298C" offset="0%" /><stop stopColor="#E5086E" offset="100%" /></linearGradient><path d="M105.643 211.75c7.884 7.884 18.25 11.696 28.589 11.566 10.338.145 20.704-3.682 28.602-11.565l84.884-84.455c29.123-29.124 29.123-76.34 0-105.464-29.108-29.11-76.322-29.11-105.43 0l-7.551 7.436-7.465-7.436c-29.108-29.11-76.322-29.11-105.43 0-29.123 29.124-29.123 76.326 0 105.45l83.801 84.469z" id="b" /><path id="c" d="M0 0v140.602h63.8V0H0z" /></defs><g fill="none" fillRule="evenodd"><use fill="url(#a)" xlinkHref="#b" /><g mask="url(#cxy)"><g transform="translate(104 84)"><mask id="d" fill="#fff"><use xlinkHref="#c" /></mask><path d="M19.471 83.717c-4.387-3.637-8.7-7.555-8.518-10.251.2-2.98 3.32-6.991 5.802-12.494 4.455 9.779 6.203 17.191 2.716 22.745m27.581-7.943c3.782-.54 5.78-2.2 6.853-3.527 4.321-5.35-13.377-22.357-21.703-29.882.585.218 1.17.426 1.75.59 4.768 1.349 8.631-5.322 10.49-9.977 5.292-13.252 3.32-27.467-7.402-31.748-10.724-4.283-23.707 2.989-29 16.241-2.843 7.119-6.096 25.89-6.096 25.89s4.97 4.972 9.868 6.588C7.99 55.316-.568 69.983.03 74.069c1.1 7.51 8.019 13.639 11.434 16.006.348.241.69.519 1.027.814-9.65 12.114-6.94 28.739-6.675 31.33 1.214 11.873 12.548 18.383 12.548 18.383h28.555s-1.284-4.938 3.064-11.941c3.43-5.527 13.817-5.887 13.817-25.875 0-13.506-10.57-24.87-16.748-27.012" fill="#FEFEFE" mask="url(#d)" /></g></g></g></svg>                      </div>
              <div className="pregnancy-calendar__interaction__icon" data-value={3} data-url="/iii-trymestr-ciazy-o-czym-pamietac-przygotowac-sie-porodu/">
                <svg width={270} height={224} viewBox="0 0 270 224" xmlns="http://www.w3.org/2000/svg"><title>Group 15</title><path d="M122.527 166.954c-4.4-3.648-8.725-7.577-8.544-10.28.202-2.988 3.331-7.011 5.82-12.53 4.467 9.807 6.22 17.24 2.724 22.81m49.33 15.872c-2.168-17.878-14.392-21.689-20.587-23.837 3.37-2.298 4.712-2.207 5.788-3.538 4.333-5.365-13.415-22.42-21.765-29.967.587.22 1.173.428 1.756.593 4.781 1.352 8.655-5.338 10.519-10.006 5.308-13.29 3.33-27.545-7.423-31.839-10.755-4.295-23.775 2.998-29.081 16.288-2.852 7.14-6.115 25.964-6.115 25.964s4.984 4.986 9.898 6.606c-3.835 5.383-12.417 20.091-11.817 24.189 1.103 7.53 8.042 13.677 11.467 16.051.349.242.69.52 1.03.816-9.678 12.148-6.961 28.82-6.696 31.42C110.05 217.47 121.416 224 121.416 224h28.637s-1.033-6.613 3.071-11.975c4.105-5.361 21.144-9.3 18.733-29.2" fill="#FEFEFE" fillRule="evenodd" /></svg>                      </div>
            </div>
            <input type="text" className="pregnancy-slider" />
            <div className="pregnancy-calendar__interaction__info__container">
              <div className="example">
                <p className="key">I trymestr</p>
                <p className="value">1 - 14 tydzień ciąży</p>
              </div>
              <div className="pregnancy-calendar__interaction__info" data-value={1}>
                <p className="key">I trymestr</p>
                <p className="value">1 - 14 tydzień ciąży</p>
              </div>
              <div className="pregnancy-calendar__interaction__info" data-value={2}>
                <p className="key">II trymestr</p>
                <p className="value">14 - 28 tydzień ciąży</p>
              </div>
              <div className="pregnancy-calendar__interaction__info" data-value={3}>
                <p className="key">III trymestr</p>
                <p className="value">28 - 40 tydzień ciąży</p>
              </div>
            </div>
            <div className="pregnancy-calendar__button visible-xs">
              <a className="btn btn-primary" href="#">
                przejdź
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
